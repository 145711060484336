/* 
I think type-display-1 & type-headline-6 is a bug in dsp, they are not present in figma plugin.
material dsp included it so i made the class anyway. 
use with caution 
*/
.type-display-1 {
  font-family: var(--md-sys-typescale-display-1-font), sans-serif;
  font-weight: var(--md-sys-typescale-display-1-weight);
  font-size: var(--md-sys-typescale-display-1-size);
}

.type-display-large {
  font-family: var(--md-sys-typescale-display-large-font), sans-serif;
  font-weight: var(--md-sys-typescale-display-large-weight);
  font-size: var(--md-sys-typescale-display-large-size);
}

.type-display-medium {
  font-family: var(--md-sys-typescale-display-medium-font), sans-serif;
  font-weight: var(--md-sys-typescale-display-medium-weight);
  font-size: var(--md-sys-typescale-display-medium-size);
}

.type-display-small {
  font-family: var(--md-sys-typescale-display-small-font), sans-serif;
  font-weight: var(--md-sys-typescale-display-small-weight);
  font-size: var(--md-sys-typescale-display-small-size);
}

.type-headline-large {
  font-family: var(--md-sys-typescale-headline-large-font), sans-serif;
  font-weight: var(--md-sys-typescale-headline-large-weight);
  font-size: var(--md-sys-typescale-headline-large-size);
}

.type-headline-medium {
  font-family: var(--md-sys-typescale-headline-medium-font), sans-serif;
  font-weight: var(--md-sys-typescale-headline-medium-weight);
  font-size: var(--md-sys-typescale-headline-medium-size);
}

.type-headline-small {
  font-family: var(--md-sys-typescale-headline-small-font), sans-serif;
  font-weight: var(--md-sys-typescale-headline-small-weight);
  font-size: var(--md-sys-typescale-headline-small-size);
}

.type-headline-6 {
  font-family: var(--md-sys-typescale-headline-6-font), sans-serif;
  font-weight: var(--md-sys-typescale-headline-6-weight);
  font-size: var(--md-sys-typescale-headline-6-size);
}

.type-title-large {
  font-family: var(--md-sys-typescale-title-large-font), sans-serif;
  font-weight: var(--md-sys-typescale-title-large-weight);
  font-size: var(--md-sys-typescale-title-large-size);
}

.type-title-medium {
  font-family: var(--md-sys-typescale-title-medium-font), sans-serif;
  font-weight: var(--md-sys-typescale-title-medium-weight);
  font-size: var(--md-sys-typescale-title-medium-size);
}

.type-title-small {
  font-family: var(--md-sys-typescale-title-small-font), sans-serif;
  font-weight: var(--md-sys-typescale-title-small-weight);
  font-size: var(--md-sys-typescale-title-small-size);
}

.type-body-large {
  font-family: var(--md-sys-typescale-body-large-font), sans-serif;
  font-weight: var(--md-sys-typescale-body-large-weight);
  font-size: var(--md-sys-typescale-body-large-size);
  line-height: 1.5em;
}

.type-body-medium {
  font-family: var(--md-sys-typescale-body-medium-font), sans-serif;
  font-weight: var(--md-sys-typescale-body-medium-weight);
  font-size: var(--md-sys-typescale-body-medium-size);
  line-height: 1.5em;
}

.type-body-small {
  font-family: var(--md-sys-typescale-body-small-font), sans-serif;
  font-weight: var(--md-sys-typescale-body-small-weight);
  font-size: var(--md-sys-typescale-body-small-size);
  line-height: 1.5em;
}

.type-label-large {
  font-family: var(--md-sys-typescale-label-large-font), sans-serif;
  font-weight: var(--md-sys-typescale-label-large-weight);
  font-size: var(--md-sys-typescale-label-large-size);
  line-height: 1.5em;
}

.type-label-medium {
  font-family: var(--md-sys-typescale-label-medium-font), sans-serif;
  font-weight: var(--md-sys-typescale-label-medium-weight);
  font-size: var(--md-sys-typescale-label-medium-size);
  line-height: 1.5em;
}

.type-label-small {
  font-family: var(--md-sys-typescale-label-small-font), sans-serif;
  font-weight: var(--md-sys-typescale-label-small-weight);
  font-size: var(--md-sys-typescale-label-small-size);
  line-height: 1.5em;
}
