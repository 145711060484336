.card {
  width: 200px;
  height: 70px;
  background-color: #fff;
  border-radius: 10px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--md-sys-color-inverse-surface-light) !important;
  padding: 0.4rem;
  box-shadow: 1px 4px 7px 0px rgba(0, 0, 0, 0.67);
  -webkit-box-shadow: 1px 4px 7px 0px rgba(0, 0, 0, 0.67);
  -moz-box-shadow: 1px 4px 7px 0px rgba(0, 0, 0, 0.67);
  > span {
    display: flex;
    gap: 0.4rem;
  }
}

