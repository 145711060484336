/**
 * Do not edit directly
 * Generated on Tue, 09 Aug 2022 09:47:51 GMT
 */

:root {
  --md-sys-color-primary-light: #b32b00;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #ffdacf;
  --md-sys-color-on-primary-container-light: #3c0800;
  --md-sys-color-secondary-light: #914c00;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #ffdcc1;
  --md-sys-color-on-secondary-container-light: #2f1400;
  --md-sys-color-tertiary-light: #006878;
  --md-sys-color-on-tertiary-light: #ffffff;
  --md-sys-color-tertiary-container-light: #9eefff;
  --md-sys-color-on-tertiary-container-light: #001f25;
  --md-sys-color-error-light: #ba1b1b;
  --md-sys-color-error-container-light: #ffdad4;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-on-error-container-light: #410001;
  --md-sys-color-background-light: #fbfdfd;
  --md-sys-color-on-background-light: #191c1d;
  --md-sys-color-surface-light: #fbfdfd;
  --md-sys-color-on-surface-light: #191c1d;
  --md-sys-color-surface-variant-light: #f5ded9;
  --md-sys-color-on-surface-variant-light: #251916;
  --md-sys-color-outline-light: #85736f;
  --md-sys-color-inverse-on-surface-light: #eff1f1;
  --md-sys-color-inverse-surface-light: #2d3132;
  --md-sys-color-inverse-primary-light: #ffb49e;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-primary-dark: #ffb49e;
  --md-sys-color-on-primary-dark: #611300;
  --md-sys-color-primary-container-dark: #891e00;
  --md-sys-color-on-primary-container-dark: #ffdacf;
  --md-sys-color-secondary-dark: #fe8b00;
  --md-sys-color-on-secondary-dark: #4e2600;
  --md-sys-color-secondary-container-dark: #b23400;
  --md-sys-color-on-secondary-container-dark: #ffdcc1;
  --md-sys-color-tertiary-dark: #22d9f5;
  --md-sys-color-on-tertiary-dark: #00363f;
  --md-sys-color-tertiary-container-dark: #004e5a;
  --md-sys-color-on-tertiary-container-dark: #9eefff;
  --md-sys-color-error-dark: #ffb4a9;
  --md-sys-color-error-container-dark: #930006;
  --md-sys-color-on-error-dark: #680003;
  --md-sys-color-on-error-container-dark: #ffdad4;
  --md-sys-color-background-dark: #191c1d;
  --md-sys-color-on-background-dark: #e0e3e3;
  --md-sys-color-surface-dark: #191c1d;
  --md-sys-color-on-surface-dark: #e0e3e3;
  --md-sys-color-surface-variant-dark: #534340;
  --md-sys-color-on-surface-variant-dark: #ffede8;
  --md-sys-color-outline-dark: #a08c87;
  --md-sys-color-inverse-on-surface-dark: #191c1d;
  --md-sys-color-inverse-surface-dark: #e0e3e3;
  --md-sys-color-inverse-primary-dark: #b32b00;
  --md-sys-color-shadow-dark: #000000;
  --md-ref-palette-primary-100: #ffffff;
  --md-ref-palette-primary-99: #fcfcfc;
  --md-ref-palette-primary-95: #ffede7;
  --md-ref-palette-primary-90: #ffdacf;
  --md-ref-palette-primary-80: #ffb49e;
  --md-ref-palette-primary-70: #ff8b68;
  --md-ref-palette-primary-60: #ff561f;
  --md-ref-palette-primary-50: #df3800;
  --md-ref-palette-primary-40: #b32b00;
  --md-ref-palette-primary-30: #891e00;
  --md-ref-palette-primary-20: #611300;
  --md-ref-palette-primary-10: #3c0800;
  --md-ref-palette-primary-0: #000000;
  --md-ref-palette-secondary-100: #ffffff;
  --md-ref-palette-secondary-99: #fcfcfc;
  --md-ref-palette-secondary-95: #ffede0;
  --md-ref-palette-secondary-90: #ffdcc1;
  --md-ref-palette-secondary-80: #ffb77a;
  --md-ref-palette-secondary-70: #ff8e08;
  --md-ref-palette-secondary-60: #dc7600;
  --md-ref-palette-secondary-50: #b66100;
  --md-ref-palette-secondary-40: #914c00;
  --md-ref-palette-secondary-30: #6f3900;
  --md-ref-palette-secondary-20: #4e2600;
  --md-ref-palette-secondary-10: #2f1400;
  --md-ref-palette-secondary-0: #000000;
  --md-ref-palette-tertiary-100: #ffffff;
  --md-ref-palette-tertiary-99: #f6fdff;
  --md-ref-palette-tertiary-95: #d0f7ff;
  --md-ref-palette-tertiary-90: #9eefff;
  --md-ref-palette-tertiary-80: #22d9f5;
  --md-ref-palette-tertiary-70: #00bcd7;
  --md-ref-palette-tertiary-60: #009fb6;
  --md-ref-palette-tertiary-50: #008396;
  --md-ref-palette-tertiary-40: #006878;
  --md-ref-palette-tertiary-30: #004e5a;
  --md-ref-palette-tertiary-20: #00363f;
  --md-ref-palette-tertiary-10: #001f25;
  --md-ref-palette-tertiary-0: #000000;
  --md-ref-palette-neutral-100: #ffffff;
  --md-ref-palette-neutral-99: #fbfdfd;
  --md-ref-palette-neutral-95: #eff1f1;
  --md-ref-palette-neutral-90: #e0e3e3;
  --md-ref-palette-neutral-80: #c4c7c7;
  --md-ref-palette-neutral-70: #a9acac;
  --md-ref-palette-neutral-60: #8e9192;
  --md-ref-palette-neutral-50: #747778;
  --md-ref-palette-neutral-40: #5c5f60;
  --md-ref-palette-neutral-30: #444748;
  --md-ref-palette-neutral-20: #2d3132;
  --md-ref-palette-neutral-10: #191c1d;
  --md-ref-palette-neutral-0: #000000;
  --md-ref-palette-neutral-variant-100: #ffffff;
  --md-ref-palette-neutral-variant-99: #fcfcfc;
  --md-ref-palette-neutral-variant-95: #ffede8;
  --md-ref-palette-neutral-variant-90: #f5ded9;
  --md-ref-palette-neutral-variant-80: #d9c2bd;
  --md-ref-palette-neutral-variant-70: #bca7a2;
  --md-ref-palette-neutral-variant-60: #a08c87;
  --md-ref-palette-neutral-variant-50: #85736f;
  --md-ref-palette-neutral-variant-40: #6c5b57;
  --md-ref-palette-neutral-variant-30: #534340;
  --md-ref-palette-neutral-variant-20: #3b2d2a;
  --md-ref-palette-neutral-variant-10: #251916;
  --md-ref-palette-neutral-variant-0: #000000;
  --md-ref-palette-error-100: #ffffff;
  --md-ref-palette-error-99: #fcfcfc;
  --md-ref-palette-error-95: #ffede9;
  --md-ref-palette-error-90: #ffdad4;
  --md-ref-palette-error-80: #ffb4a9;
  --md-ref-palette-error-70: #ff897a;
  --md-ref-palette-error-60: #ff5449;
  --md-ref-palette-error-50: #dd3730;
  --md-ref-palette-error-40: #ba1b1b;
  --md-ref-palette-error-30: #930006;
  --md-ref-palette-error-20: #680003;
  --md-ref-palette-error-10: #410001;
  --md-ref-palette-error-0: #000000;
  --sys-color-surface-tint-at-1-dark: rgba(255, 180, 158, 0.05);
  --sys-color-surface-tint-at-2-dark: rgba(255, 180, 158, 0.08);
  --sys-color-surface-tint-at-3-dark: rgba(255, 180, 158, 0.11);
  --sys-color-surface-tint-at-4-dark: rgba(255, 180, 158, 0.11);
  --sys-color-surface-tint-at-5-dark: rgba(255, 180, 158, 0.14);
  --sys-color-surface-tint-at-1-light: rgba(179, 43, 0, 0.05);
  --sys-color-surface-tint-at-2-light: rgba(179, 43, 0, 0.08);
  --sys-color-surface-tint-at-3-light: rgba(179, 43, 0, 0.11);
  --sys-color-surface-tint-at-4-light: rgba(179, 43, 0, 0.12);
  --sys-color-surface-tint-at-5-light: rgba(179, 43, 0, 0.14);
  --sys-color-surface-tint-at-0-dark: rgba(255, 180, 158, 0);
  --sys-color-surface-tint-at-0-light: rgba(179, 43, 0, 0);
  --md-sys-color-success-light: #006e24;
  --md-sys-color-on-success-light: #ffffff;
  --md-sys-color-success-dark: #58e070;
  --md-sys-color-on-success-dark: #00390e;
  --md-sys-color-success-container-dark: #005319;
  --md-sys-color-on-success-container-dark: #76fd89;
  --md-sys-color-success-container-light: #76fd89;
  --md-sys-color-on-success-container-light: #002106;
  --md-sys-color-disabled-container-light: rgba(25, 28, 29, 0.12);
  --md-sys-color-disabled-content-light: rgba(25, 28, 29, 0.38);
  --md-sys-color-disabled-container-dark: rgba(224, 227, 227, 0.12);
  --md-sys-color-disabled-content-dark: rgba(224, 227, 227, 0.38);
  --md-sys-color-disabled-border-light: rgba(25, 28, 29, 0.6);
  --md-sys-color-disabled-border-dark: rgba(224, 227, 227, 0.6);
  --md-sys-typescale-display-1-family: Roboto;
  --md-sys-typescale-display-1-weight: 400;
  --md-sys-typescale-display-large-family: Roboto;
  --md-sys-typescale-display-large-weight: 400;
  --md-sys-typescale-display-medium-family: Roboto;
  --md-sys-typescale-display-medium-weight: 400;
  --md-sys-typescale-display-small-family: Roboto;
  --md-sys-typescale-display-small-weight: 400;
  --md-sys-typescale-headline-large-family: Roboto;
  --md-sys-typescale-headline-large-weight: 400;
  --md-sys-typescale-headline-medium-family: Roboto;
  --md-sys-typescale-headline-medium-weight: 400;
  --md-sys-typescale-headline-small-family: Roboto;
  --md-sys-typescale-headline-small-weight: 400;
  --md-sys-typescale-title-large-family: Roboto;
  --md-sys-typescale-title-large-weight: 400;
  --md-sys-typescale-headline-6-family: Roboto;
  --md-sys-typescale-headline-6-weight: 400;
  --md-sys-typescale-title-medium-family: Roboto;
  --md-sys-typescale-title-medium-weight: 500;
  --md-sys-typescale-title-small-family: Roboto;
  --md-sys-typescale-title-small-weight: 500;
  --md-sys-typescale-label-large-family: Roboto;
  --md-sys-typescale-label-large-weight: 500;
  --md-sys-typescale-body-large-family: Roboto;
  --md-sys-typescale-body-large-weight: 400;
  --md-sys-typescale-body-medium-family: Roboto;
  --md-sys-typescale-body-medium-weight: 400;
  --md-sys-typescale-body-small-family: Roboto;
  --md-sys-typescale-body-small-weight: Regular;
  --md-sys-typescale-label-medium-family: Roboto;
  --md-sys-typescale-label-medium-weight: 500;
  --md-sys-typescale-label-small-family: Roboto;
  --md-sys-typescale-label-small-weight: Medium;
  --md-sys-typescale-display-1-size: 6.4rem;
  --md-sys-typescale-display-large-size: 5.7rem;
  --md-sys-typescale-display-medium-size: 4.5rem;
  --md-sys-typescale-display-small-size: 3.6rem;
  --md-sys-typescale-headline-large-size: 3.2rem;
  --md-sys-typescale-headline-medium-size: 2.8rem;
  --md-sys-typescale-headline-small-size: 2.4rem;
  --md-sys-typescale-title-large-size: 2.2rem;
  --md-sys-typescale-headline-6-size: 1.8rem;
  --md-sys-typescale-title-medium-size: 1.6rem;
  --md-sys-typescale-title-small-size: 1.4rem;
  --md-sys-typescale-label-large-size: 1.4rem;
  --md-sys-typescale-body-large-size: 1.6rem;
  --md-sys-typescale-body-medium-size: 1.4rem;
  --md-sys-typescale-body-small-size: 1.2rem;
  --md-sys-typescale-label-medium-size: 1.2rem;
  --md-sys-typescale-label-small-size: 1.1rem;
  --interaction-layer-hovered: 0.08; /* opacity of a layer on top of interactive ui, that indicates element is hovered */
  --interaction-layer-focused: 0.12; /* opacity of a layer on top of interactive ui, that indicates element is focused */
  --interaction-layer-pressed: 0.12; /* opacity of a layer on top of interactive ui, that indicates element is pressed */
  --interaction-layer-dragged: 0.24; /* opacity of a layer on top of interactive ui, that indicates element is being dra */
  --umbra-1: 0px 2px 1px -1px;
  --umbra-2: 0px 3px 1px -2px;
  --umbra-3: 0px 3px 3px -2px;
  --umbra-4: 0px 2px 4px -1px;
  --umbra-5: 0px 3px 5px -1px;
  --umbra-6: 0px 3px 5px -1px;
  --umbra-7: 0px 4px 5px -2px;
  --umbra-8: 0px 5px 5px -3px;
  --umbra-9: 0px 5px 6px -3px;
  --umbra-10: 0px 6px 6px -3px;
  --umbra-11: 0px 6px 7px -4px;
  --umbra-12: 0px 7px 8px -4px;
  --umbra-13: 0px 7px 8px -4px;
  --umbra-14: 0px 7px 9px -4px;
  --umbra-15: 0px 8px 9px -5px;
  --umbra-16: 0px 8px 10px -5px;
  --umbra-17: 0px 8px 11px -5px;
  --umbra-18: 0px 9px 11px -5px;
  --umbra-19: 0px 9px 12px -6px;
  --umbra-20: 0px 10px 13px -6px;
  --umbra-21: 0px 10px 13px -6px;
  --umbra-22: 0px 10px 14px -6px;
  --umbra-23: 0px 11px 14px -7px;
  --umbra-24: 0px 11px 15px -7px;
  --penumbra-1: 0px 1px 1px 0px;
  --penumbra-2: 0px 2px 2px 0px;
  --penumbra-3: 0px 3px 4px 0px;
  --penumbra-4: 0px 4px 5px 0px;
  --penumbra-5: 0px 5px 8px 0px;
  --penumbra-6: 0px 6px 10px 0px;
  --penumbra-7: 0px 7px 10px 1px;
  --penumbra-8: 0px 8px 10px 1px;
  --penumbra-9: 0px 9px 12px 1px;
  --penumbra-10: 0px 10px 14px 1px;
  --penumbra-11: 0px 11px 15px 1px;
  --penumbra-12: 0px 12px 17px 2px;
  --penumbra-13: 0px 13px 19px 2px;
  --penumbra-14: 0px 14px 21px 2px;
  --penumbra-15: 0px 15px 22px 2px;
  --penumbra-16: 0px 16px 24px 2px;
  --penumbra-17: 0px 17px 26px 2px;
  --penumbra-18: 0px 18px 28px 2px;
  --penumbra-19: 0px 19px 29px 2px;
  --penumbra-20: 0px 20px 31px 3px;
  --penumbra-21: 0px 21px 33px 3px;
  --penumbra-22: 0px 22px 35px 3px;
  --penumbra-23: 0px 23px 36px 3px;
  --penumbra-24: 0px 24px 38px 3px;
  --ambient-1: 0px 1px 3px 0px;
  --ambient-24: 0px 9px 46px 8px;
  --ambient-23: 0px 9px 44px 8px;
  --ambient-22: 0px 8px 42px 7px;
  --ambient-21: 0px 8px 40px 7px;
  --ambient-20: 0px 8px 38px 7px;
  --ambient-19: 0px 7px 36px 6px;
  --ambient-18: 0px 7px 34px 6px;
  --ambient-17: 0px 6px 32px 5px;
  --ambient-16: 0px 6px 30px 5px;
  --ambient-15: 0px 6px 28px 5px;
  --ambient-14: 0px 5px 26px 4px;
  --ambient-13: 0px 5px 24px 4px;
  --ambient-12: 0px 5px 22px 4px;
  --ambient-11: 0px 4px 20px 3px;
  --ambient-10: 0px 4px 18px 3px;
  --ambient-9: 0px 3px 16px 2px;
  --ambient-8: 0px 3px 14px 2px;
  --ambient-7: 0px 2px 16px 1px;
  --ambient-6: 0px 1px 18px 0px;
  --ambient-5: 0px 1px 14px 0px;
  --ambient-4: 0px 1px 10px 0px;
  --ambient-3: 0px 1px 8px 0px;
  --ambient-2: 0px 1px 5px 0px;
  --umbra-opacity: 0.2;
  --penumbra-opacity: 0.14;
  --ambient-opacity: .12;
}
