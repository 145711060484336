.currency {
    display: flex;
    align-items: center;

}

.currency > img {
    margin-right: 10px;
    width: 30px;

}