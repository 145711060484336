.op-filter-wrapper {
    width: 100%;
    background-color: var(--srf-main-light);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter', sans-serif;
    font-size: 1.5rem;
    padding: 1rem;
    position: relative;
  }
  
  .op-filter-main {
    /* width: 50%; */
    display: flex;
    justify-content: space-around;
  }
  
  .op-filter-main__btn {
    min-width: 80px;
    width: 80px;
    text-align: center;
    padding: 0.5rem 1rem;
    background-color: var(--color-background);
    color: var(--color-on-surface);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: 10px;
    font-weight: 500;
  }
  
  .btn-active {
    background-color: var(--primary);
  }
  
  .op-filter-main__dropdown {
    position: relative;
  }
  
  .op-filter-main__dropdown > .op-filter-main__btn {
    height: 100%;
  }
  
  .op-filter-dd {
    position: absolute;
    top: 30px;
    left: 0;
    background-color: var(--btn-filter);
    color: var(--txt-accent);
    width: 80px;
    padding: 0.5rem 1rem;
    height: 0;
    /* max-height: 200px; */
    opacity: 0;
    overflow-y: scroll;
    transition: all 0.5s ease;
    z-index: 10;
  }
  
  .show-filter-dd {
    opacity: 1;
    height: 200px;
  }
  
  .op-filter-dd ul {
    list-style: none;
  }
  
  .op-filter-dd ul li {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    color: #000;
  }
  
  .op-filter__date {
    padding: 0.5rem 1rem;
    background-color: var(--color-background);
    
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    width: 200px;
    min-width: 160px;
    color: #000;
    display: flex;
    align-items: center;
    font-weight: 500;
  }
  
  .op-filter__date > span {
    margin-right: 5px;
    color: var(--color-on-surface);
  }
  
  .datePicker {
    width: 90%;
  }
  
  .datePicker input {
    width: 100%;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;
    font-size: 1.3rem;
  }
  
  .datePicker input:focus {
    outline: none;
  }
  .datePicker input::placeholder {
    color: var(--color-on-surface);
  }
  
  .op-filter-search {
    display: flex;
  }
  
  .op-filter-search > div,
  .op-filter-search-resp > div {
    border: 1.5px solid var(--border);
    border-radius: 3px;
    margin-right: 10px;
    padding: 0.5rem;
  }
  .op-filter-search-resp > div {
    margin-bottom: 10px;
  }
  
  .op-filter-search > div > input,
  .op-filter-search-resp > div > input {
    border: none;
    background-color: transparent;
    color: var(--txt-main);
    padding-left: 0.5rem;
  }
  .op-filter-search > div > input:focus,
  .op-filter-search-resp > div > input:focus {
    border: none;
    outline: none;
  }
  .op-filter-search > div > input::placeholder,
  .op-filter-search-resp > div > input::placeholder {
    color: var(--txt-main);
    font-size: 1.3rem;
    font-family: 'Inter', sans-serif;
  }
  
  .op-filter-resp {
    position: absolute;
    display: block;
    top: 0;
    left: -1000px;
    width: 250px;
    background-color: var(--bg-form);
    padding: 1rem;
    border: 1px solid var(--btn-main);
    z-index: 10;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
    border-bottom-right-radius: 5px;
    overflow: auto;
    /* display: none; */
    transition: all 0.5s ease;
  }
  
  .op-filter-resp-show {
    /* display: block; */
    left: 0;
  }
  
  .op-filter-main-resp > span,
  .op-filter-main-resp > div {
    margin-bottom: 10px;
  }
  
  .resp-filter-btn {
    display: none;
    background-color: var(--primary);
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.5rem;
    width: 100px;
    height: 30px;
  }
  
  @media screen and (max-width: 1300px) {
    .op-filter-wrapper {
      flex-direction: column;
    }
  
    .op-filter-main {
      width: 100%;
      justify-content: space-between;
    }
  
    .op-filter-search,
    .op-filter-search-resp {
      width: 100%;
      margin-top: 10px;
      justify-content: space-between;
    }
  
    .op-filter-search > div,
    .op-filter-search-resp > div {
      margin-right: 0;
    }
  }
  
  @media screen and (max-width: 750px) {
    .op-filter-wrapper {
      align-items: flex-start;
    }
  
    .op-filter-main {
      display: none;
    }
    .op-filter-search {
      display: none;
    }
  
    .resp-filter-btn {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
  