/* 
animation can be overridden by redefinining
individual animation properties (eg: animation-tyming-function)
after animation declaration.
*/

:root {
  --animation-shake: animation-shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97)
    both;
}

@keyframes animation-shake {
  20%,
  50%,
  80% {
    transform: translate3d(-4px, 0, 0);
  }

  35%,
  65% {
    transform: translate3d(4px, 0, 0);
  }
}
