.user {
    color: var(--txt-main);
}

.user-info-row {
    background-color: var(--srf-main);
    margin: 1rem 0;
    padding: 1rem;
}

.user-info-row__title {
    text-transform: capitalize;
    color: white;
    font-weight: 600;
}