.errorMessage,
.description {
  composes: type-body-medium from global;
  margin-top: 0.4rem;
}

.errorMessage {
  color: var(--md-ref-palette-error-50);
}

/* if error message is present, hide the description */
.errorMessage ~ .description {
  display: none;
}
