.underlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-inverse-surface);
  opacity: 0.3;
  z-index: 1;
}

.overlay {
  /* important: inline-flex helps remove scrollbar when child has padding */
  display: inline-flex;
  flex-direction: column;

  border-radius: 2.4rem 2.4rem 0 0;
  width: 100%;
  max-width: 48rem;
  max-height: calc(100% - 7.2rem);
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow-y: auto;
  z-index: 2;
}

.overlay:focus {
  outline: none;
}
