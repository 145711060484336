.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-header);
  height: 60px;
  padding: 0 2rem;

  /* position: absolute;
  top: 0;
  left: 1rem;
  width: 100%;
  height: 50px; */
}

/* .header-white {
  background-color: var(--bg-header);
} */

.header__logo {
  width: 15%;
  min-width: 150px;
}

.header__logo > img {
  width: 100%;
}

.header__actions {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.user-icon {
  height: 70%;

  padding: 10px;
  cursor: pointer;
}

.user-icon > img {
  height: 100%;
}

.theme_toggler
{
  cursor: pointer;
  > .icon {
    color: white;
  }
}