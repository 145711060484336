/* TODO: smooth transitions, Focus layer, Press layer, error variant */

.groupContainer {
  display: inline-flex;
  flex-direction: column;
  gap: 0.4rem;
  > span {
    color: var(--md-sys-color-inverse-surface-dark) !important;
  }
}

.checkboxesWrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: start;
}

.groupContainer:global(.--isHorizontal) .checkboxesWrapper {
  flex-direction: row;
  gap: 0.4rem 2.4rem;
  flex-wrap: wrap;
}

.groupLabel {
  composes: type-label-medium from global;
}

.checkboxLabel {
  composes: type-label-large from global;
  display: inline-flex;
  gap: 0.8rem;
  min-height: 3.6rem;
  min-width: 3.6rem;
  align-items: center;
  justify-content: center;
  opacity: 0.86;
  > span {
    color: var(--md-sys-color-inverse-surface-dark) !important;
  }
}

/* .checkboxLabel:hover */

.checkMark {
  position: relative;
  display: inline-grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 2.4rem;
  height: 2.4rem;
  place-items: center;
}

.checkboxLabel:global(.--focusRing) .checkMark::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: calc(100% + 1.2rem);
  height: calc(100% + 1.2rem);
  transform: translate(-0.6rem, -0.6rem);
  border-radius: 50%;
  box-shadow: 0 0 0 2px var(--color-on-surface);
}

.checkboxLabel:not(:global(.--isDisabled)):hover {
  opacity: 1;
}
.checkboxLabel:not(:global(.--isDisabled)):hover .checkMark::before {
  content: '';
  top: -37.5%;
  left: -37.5%;
  display: block;
  position: absolute;
  width: 175%;
  height: 175%;
  border-radius: 50%;
  background-color: var(--color-primary);
  opacity: var(--interaction-layer-hovered);
}

.checkboxLabel:global(.--isDisabled) {
  color: var(--md-sys-color-inverse-surface-dark) !important;
}

.checkMark > * {
  grid-column: 1;
  grid-row: 1;
  color: var(--md-sys-color-inverse-surface-dark) !important;
  display: inline-block;
}
