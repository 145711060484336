.settingsContainer {
    margin-top: 6rem;
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
    color: aliceblue;
    background-color: gray;
    padding: 3rem;
    border-radius: 10px;
    width: auto;
    height: auto;
    overflow-y: auto;
}

.headersSettings {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    max-height: 1rem;
}

.settingsTable {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;
    row-gap: 3rem;
}

.settingsTableAdd {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;
    row-gap: 3rem;
    padding: 1rem;
    border: solid white 5px;
    border-radius: 1rem;
    background: rgba(0, 0, 0, 0.3);
}

.input {
    width: 20rem;
    height: 3rem;
    padding-left: 2rem;
    font-weight: 500;
}

.input::placeholder {
    font-weight: 500;
    opacity: 1;
}

.headerSettings {
    font-size: larger;
    display: flex;
}

.settingName {
    display: flex;
    height: 3rem;
}

.settingNameAdd {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.settingValue {
    display: flex;
    gap: 1rem;
    height: 3rem;
}

.settingValueAdd {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 3rem;
}

.inputContainer {
    display: flex;
    gap: 1rem;
}

.errorContainer {
    display: flex;
    gap: 1.5rem;
}

.closeButton {
    border: solid #dd3730 2px;
    border-radius: 50%;
    height: 2.2rem;
    width: 2.2rem;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    
    .settingsContainer {
        margin-left: 0rem;
    }
    .settingsTable {
        grid-template-columns: 1fr;
    }
    
}