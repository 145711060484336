.pagination {
    /* margin-left: 200px; */
    display: flex;
    justify-content: center;
    background-color: var(--srf-main);
    padding: 2rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .pagination > li {
    display: inline-block;
    padding-left: 0;
    margin: 0 5px;
  }
  .pagination > li {
    list-style: none;
    /* border: 0.9px solid;  */
  }
  .pagination > li > a,
  .pagination > li > span {
    cursor: pointer;
    position: relative;
    float: left;
    padding: 3px 6px;
    line-height: 1.42857143;
    text-decoration: none;
    color: var(--txt-main);
    /* background-color: var(--bg-main);   */
    /* border: 1px solid var(--bg-main); */
    margin-left: -1px;
  }
  
  .pagination > li.active > a {
    background-color: var(--primary);
  }
  
  /* Style the active class (and buttons on mouse-over) */
  .pagination > li > a:hover {
    background-color: var(--primary);
  }
  
  
  @media screen and (max-width: 800px) {
    .pagination {
      margin: 0 auto;
      width: 80%;
    }
  }
  
  @media screen and (max-width: 650px) {
    .pagination {
      width: 100%;
    }
  }
  