.cell {
    cursor: default;
    text-align: center;
    font-size: 1.4rem;
    padding: 6px 8px;
    margin: 2px;
    border-radius: 6px;
    position: relative;
    outline: none;
    color: var(--color-on-surface);
}

.cell:hover {
    background: var(--color-logo);
    color: var(--color-surface);
    cursor:pointer;
}

.cell.selected {
    background: var(--color-logo);
    color: var(--color-surface);
}

.cell.focusRing::after {
    content: "";
    position: absolute;
    inset: -4px;
    border-radius: 9px;
    z-index: -1;
}
  
.cell.disabled {
    color: gray;
}