* {
  --bg-header: #1e2a31;
  --bg-main: #22313a;
  --bg-form: #22313a;
  --alert-bg: #f5f5f5;

  --bg-main-border: rgba(34, 49, 58, 0.5);
  --border: #d4d4d465;
  /* --srf-main: #2a3841; */
  --dashboard-bg: #22313a;
  --srf-main: rgba(42, 56, 65, 0.7);
  --srf-main-light: rgba(42, 56, 65, 0.5);
  --srf-accent: rgba(245, 245, 245, 0.2);
  --btn-main: #d4d4d4;
  --btn-filter: #959595;
  --txt-main: #fff;
  --txt-accent: #000;
  --txt-dark: #828b90;
  --land-txt-dark: #b0b1b2;
  --primary: #ff9800;
  --bg-icon: #2a3841;

  --bg-success: #006400;
  --bg-warning: #cc0000;
}

.background-wrapper {
  background: url('./assets//pictures/bgElement5.svg'), var(--bg-main);
  background-size: cover;
  background-position: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: -1;
  will-change: transform;
}

.main-wrapper {
  display: flex;
}

h1 {
  font-size: 2.5rem;
}

/* Default page styling */
.page {
  width: 100%;
  padding: 1rem;
  overflow-y: scroll;
  height: calc(100vh - 50px);
  padding-bottom: 8rem;
}

.page > h1 {
  text-align: center;
  font-size: 2.5rem;
  color: var(--txt-main);
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/* Global Table Styles */
table {
  min-width: 80rem;
  overflow: scroll;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid var(--border);
  color: var(--txt-main);
}

thead {
  background-color: var(--form-bg);
  font-size: 1.6rem;

  /* border: 1 */
  /* border-radius: 50px; */
}

th {
  border: 1px solid var(--border);
  padding: 20px 0;
  font-weight: 500;
}

tbody {
  background-color: var(--srf-main-light);
  min-height: 400px;
  font-size: 1.4rem;
  /* border: 2px solid var(--border); */
  align-items: center;
  justify-items: center;
}

td {
  padding: 1rem;
  word-break: break-word;
  text-align: center;
}
