:root {
	--shadow-0: 0px 0px 0px 0px rgba(0, 0, 0, var(--umbra-opacity)),
		0px 0px 0px 0px rgba(0, 0, 0, var(--penumbra-opacity)),
		0px 0px 0px 0px rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-1: var(--umbra-1) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-1) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-1) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-2: var(--umbra-2) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-2) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-2) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-3: var(--umbra-3) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-3) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-3) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-4: var(--umbra-4) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-4) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-4) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-5: var(--umbra-5) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-5) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-5) rgba(0, 0, 0, var(--ambient-opacity));

	/* I could not find level-6 and above in the new material spec so USE WITH CAUTION   */
	--shadow-6: var(--umbra-6) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-6) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-6) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-7: var(--umbra-7) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-7) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-7) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-8: var(--umbra-8) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-8) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-8) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-9: var(--umbra-9) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-9) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-9) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-10: var(--umbra-10) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-10) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-10) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-11: var(--umbra-11) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-11) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-11) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-12: var(--umbra-12) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-12) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-12) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-13: var(--umbra-13) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-13) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-13) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-14: var(--umbra-14) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-14) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-14) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-15: var(--umbra-15) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-15) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-15) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-16: var(--umbra-16) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-16) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-16) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-17: var(--umbra-17) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-17) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-17) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-18: var(--umbra-18) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-18) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-18) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-19: var(--umbra-19) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-19) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-19) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-20: var(--umbra-20) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-20) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-20) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-21: var(--umbra-21) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-21) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-21) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-22: var(--umbra-22) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-22) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-22) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-23: var(--umbra-23) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-23) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-23) rgba(0, 0, 0, var(--ambient-opacity));
	--shadow-24: var(--umbra-24) rgba(0, 0, 0, var(--umbra-opacity)),
		var(--penumbra-24) rgba(0, 0, 0, var(--penumbra-opacity)),
		var(--ambient-24) rgba(0, 0, 0, var(--ambient-opacity));
}
