.submit-btn {
  background-color: var(--primary);
  padding: 1rem;
  width: 100%;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  font-weight: 700;
  margin-top: 20px;
}

.main-btn {
  background-color: var(--primary);
  color: var(--txt-main);
  margin: 20px;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.5s ease;
  display: block;
}

.main-btn:active {
  transform: scale(0.9);
}
