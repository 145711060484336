.cards-container {
  background-color: var(--srf-main);
  width: 100%;
  padding: 1rem;

  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  grid-gap: 10px;
  justify-content: space-around;
}
