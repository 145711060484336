.coin-status-card {
    width: 300px;
    height: 150px;

    display: flex;
    flex-direction: column;

    padding: 1rem;
    color: var(--txt-main);

    border-radius: 5px;
}

.p {
    display: flex;
    justify-content: space-between;
}

.bg-success {
    background-color: var(--bg-success);
}
.bg-warning {
    background-color: var(--bg-warning);
}