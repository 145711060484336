.focusRing {
  --height: 4rem;
  display: inline-block;
  border-radius: calc(var(--height) / 2);
  position: relative;
}

.focusRing:global(.--isKeyboardFocused)::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 0.6rem);
  height: calc(100% + 0.6rem);
  transform: translate(-0.3rem, -0.3rem);
  border-radius: calc(var(--height) / 2 + 0.3rem);
  box-shadow: 0 0 0 2px var(--color-on-surface);
}

.focusRing:global(.--isKeyboardFocused) .button {
  border-color: transparent;
}

.button {
  background-color: transparent;
  position: relative;
  height: var(--height);
  width: 100%;
  padding: 0 24px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* used for: hover, focus, pressed */
.button::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  border: 2px solid transparent; 
  background: linear-gradient(#FF8A5F,#D95838) border-box;
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; 
          mask-composite: exclude; 
  opacity: 0.3;
  transition: opacity 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.button.hasLeftIcon {
  padding-left: 16px;
}

.button.hasRightIcon {
  padding-right: 16px;
}

.buttonContent {
  color: var(--color-inverse-surface);
  transition: color 0.15s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}

.label {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttonContent > *:not(.label) {
  font-size: 1.8rem;
}

/* margin left on every element (exept first) if multiple */
.buttonContent > *:not(:first-child) {
  margin-left: 8px;
}

/* disabled state */

.button:disabled {
  background-color: transparent;
  border-color: var(--color-disabled-border);
  cursor: auto;
}

.button:disabled .buttonContent {
  color: var(--color-disabled-content);
}

/* hovered state */

.button:not(:disabled):hover::before {
  opacity: 1;
}

/* focused state */

.button:focus {
  outline: none;
  border-color: var(--color-primary);
}

.button:focus::before {
  opacity: var(--interaction-layer-focused);
}

/* pressed state */

.button:global(.--is-pressed) {
  border-color: var(--color-outline);
}

.button:global(.--is-pressed)::before {
  opacity: var(--interaction-layer-pressed);
}
