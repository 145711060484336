.wrapper {
  --input-height: 4rem;
  display: inline-flex;
  flex-direction: column;
}

.label {
  composes: type-label-medium from global;
  grid-area: label;
  margin-bottom: 0.2rem;
  display: inline-block;
}

.field {
  composes: type-body-medium from global;
  height: var(--input-height);
  font-size: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  /* 
  when wrapper does not render surrounding components, the padding will be 1.6rem.
  otherwise padding will hug surrounding components. 
  */
  padding-left: 1.6rem;
  padding-right: 2.4rem;
  border-width: 0;
  border-radius: 10px;
  /* border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color-on-surface); */
  background-color: var(--color-background);
  color: var(--color-on-surface);
}

.field:placeholder-shown {
  opacity: 0.9;
}

.field::placeholder {
  color: var(--color-on-surface-variant);
  opacity: 0.5;
}

.field:focus-within {
  outline: none;
  opacity: 1;
  box-shadow: 0 0 0 2px var(--color-on-surface);
}

.field:global(.--isDisabled) {
  background-color: var(--color-disabled-container);
  color: var(--color-disabled-content);
  border-bottom-color: var(--color-disabled-border);
  cursor: auto;
}
