/* Generated Styles - Do Not Edit */

.--is-light-theme {
  --color-primary: var(--md-sys-color-primary-light);
  --color-on-primary: var(--md-sys-color-on-primary-light);
  --color-primary-container: var(--md-sys-color-primary-container-light);
  --color-on-primary-container: var(--md-sys-color-on-primary-container-light);
  --color-secondary: var(--md-sys-color-secondary-light);
  --color-on-secondary: var(--md-sys-color-on-secondary-light);
  --color-secondary-container: var(--md-sys-color-secondary-container-light);
  --color-on-secondary-container: var(
    --md-sys-color-on-secondary-container-light
  );
  --color-tertiary: var(--md-sys-color-tertiary-light);
  --color-on-tertiary: var(--md-sys-color-on-tertiary-light);
  --color-tertiary-container: var(--md-sys-color-tertiary-container-light);
  --color-on-tertiary-container: var(
    --md-sys-color-on-tertiary-container-light
  );
  --color-error: var(--md-sys-color-error-light);
  --color-on-error: var(--md-sys-color-on-error-light);
  --color-error-container: var(--md-sys-color-error-container-light);
  --color-on-error-container: var(--md-sys-color-on-error-container-light);
  --color-outline: var(--md-sys-color-outline-light);
  --color-background: var(--md-sys-color-background-light);
  --color-on-background: var(--md-sys-color-on-background-light);
  --color-surface: var(--md-sys-color-surface-light);
  --color-on-surface: var(--md-sys-color-on-surface-light);
  --color-surface-variant: var(--md-sys-color-surface-variant-light);
  --color-on-surface-variant: var(--md-sys-color-on-surface-variant-light);
  --color-inverse-surface: var(--md-sys-color-inverse-surface-light);
  --color-inverse-on-surface: var(--md-sys-color-inverse-on-surface-light);
  --color-surface-tint-at-0: var(--sys-color-surface-tint-at-0-light);
  --color-surface-tint-at-1: var(--sys-color-surface-tint-at-1-light);
  --color-surface-tint-at-2: var(--sys-color-surface-tint-at-2-light);
  --color-surface-tint-at-3: var(--sys-color-surface-tint-at-3-light);
  --color-surface-tint-at-4: var(--sys-color-surface-tint-at-4-light);
  --color-surface-tint-at-5: var(--sys-color-surface-tint-at-5-light);
  --color-success: var(--md-sys-color-success-light);
  --color-on-success: var(--md-sys-color-on-success-light);
  --color-success-container: var(--md-sys-color-success-container-light);
  --color-on-success-container: var(--md-sys-color-on-success-container-light);
  --color-disabled-container: var(--md-sys-color-disabled-container-light);
  --color-disabled-content: var(--md-sys-color-disabled-content-light);
  --color-logo: #ff4500;
  --color-logo-opacity-50: rgba(255, 69, 0, 0.7);
  --color-logo-light: #e0e3e3;
  --color-logo-inverse: #fe8B00;
  --color-search-bar: #e0e3e3;
  --color-xrp: #75e6ff;
  --color-usdc: #78ace5;
  --color-xlm: #e27ff5;
  --color-busd: #f6cf4b;
  --color-reef: #dc75c6;
  --color-shib: #ff8f1a;
  --color-doge: #f5cb3b;
  --color-btt: #955ec9;

  color-scheme: light;
}
