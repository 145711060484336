.input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* justify-content: center; */
}

.input-wrapper > input {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 1rem;
  margin-bottom: 10px;
  color: black;
}

.input-wrapper > input::placeholder {
  color: black;
}

.input-wrapper > input:focus {
  border-color: #2684ff;
}
