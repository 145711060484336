.button {
    position: sticky;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    z-index: 2;
    top: 0.8rem;
    margin-bottom: 0.8rem;
    right: 0;
    align-self: center;
    width: 6rem;
    color: var(--color-surface);
    background-color: var(--color-logo);
    border: none;
    padding: 0.5rem;
    width: 6rem;
    border-radius: 3rem;
    cursor: pointer;
}

.button:hover {
    box-shadow: 0px 0px 10px var(--color-logo);
    transition: opacity 0.15s ease-in-out;
}
  