.users {
    padding-bottom: 8rem;
}

table {
    border: none;
    border-radius: 10px;
}

th {
    border: none;
    background-color: rgba(18,25,25,0.8);
}

th:first-child {
    border-top-left-radius: 10px;
}

th:last-child {
    border-top-right-radius: 10px;
}

td:first-child {
    align-items: center;
    justify-content: center;
}

.tableCell {
    align-items: center;
    justify-content: center;
    margin-left: 10rem;
}

.approved {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.not_approved {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.checkIcon {
    margin-left: 1rem;
    margin-top: 1rem;
    color: white;
    margin-right: 0.85rem;
}

.pie {
    min-height: 4rem;
    min-width: 4rem;
    border-radius: 50%;
    background-color: #fe8B00;
    margin-left: 5%;
}

.pieUnapproved {
    min-height: 4rem;
    min-width: 4rem;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid #fe8B00;
    margin-left: 5%;
}

.approval_item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}