.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 15;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 160px;
  height: 160px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 16px;
  width: 32px;
  background: var(--primary);
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 16px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 64px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 112px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 16px;
    height: 128px;
  }
  50%,
  100% {
    top: 48px;
    height: 64px;
  }
}
