.container {
  display: inline-flex;
  flex-direction: column;
}

.label {
  composes: type-label-medium from global;
  margin-bottom: 0.2rem;
  display: inline-block;
}

.button {
  composes: type-label-large from global;
  width: 100%;
  display: flex;
  align-items: center;
  height: 4rem;
  padding-left: 1.6rem;
  padding-right: 4rem;
  border-width: 0;
  border-radius: 10px;
  /* border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color-on-surface); */
  background-color: var(--color-background);
  color: var(--color-on-surface);
  cursor: pointer;
  position: relative;
}

.button:focus {
  outline: none;
  border-bottom-color: transparent;
  opacity: 1;
  border-radius: 10px;
}

.right {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 1.2rem;
  gap: 0.8rem;
}

.errorMessage,
.description {
  composes: type-body-medium from global;
  margin-top: 0.4rem;
}

.errorMessage {
  color: var(--md-ref-palette-error-50);
}

/* if error message is present, hide the description */
.errorMessage ~ .description {
  display: none;
}

/* ============== modifiers ================== */

.container:global(.--isValid) {
  color: var(--color-success);
}

.container:global(.--isValid) .button {
  border-bottom-color: var(--color-success);
}

.container:global(.--isValid) .button:focus {
  border-bottom-color: transparent;
  box-shadow: 0 0 0 2px var(--color-success);
}

/* isInvalid */
.container:global(.--isInvalid) .right > *:first-child {
  color: var(--md-ref-palette-error-50);
}

.container:global(.--isInvalid) .button {
  padding-right: 8rem;
}

.container:global(.--isInvalid) .label {
  animation: var(--animation-shake);
  transform: translate3d(0, 0, 0);
  color: var(--md-ref-palette-error-50);
}

.container:global(.--isInvalid) .button {
  border-bottom-color: var(--md-ref-palette-error-50);
}

.container:global(.--isInvalid) .button:focus {
  border-bottom-color: transparent;
}

.container:global(.--isInvalid) .button:focus {
  box-shadow: 0 0 0 2px var(--md-ref-palette-error-50);
}

/* isDisabled */
.container:global(.--isDisabled) {
  color: var(--color-disabled-content);
}

.container:global(.--isDisabled) .button {
  background-color: var(--color-disabled-container);
  color: var(--color-disabled-content);
  border-bottom-color: var(--color-disabled-border);
  cursor: auto;
}
