/* Generated Styles - Do Not Edit */

.--is-dark-theme {
  --color-primary: var(--md-sys-color-primary-dark);
  --color-on-primary: var(--md-sys-color-on-primary-dark);
  --color-primary-container: var(--md-sys-color-primary-container-dark);
  --color-on-primary-container: var(--md-sys-color-on-primary-container-dark);
  --color-secondary: var(--md-sys-color-secondary-dark);
  --color-on-secondary: var(--md-sys-color-on-secondary-dark);
  --color-secondary-container: var(--md-sys-color-secondary-container-dark);
  --color-on-secondary-container: var(
    --md-sys-color-on-secondary-container-dark
  );
  --color-tertiary: var(--md-sys-color-tertiary-dark);
  --color-on-tertiary: var(--md-sys-color-on-tertiary-dark);
  --color-tertiary-container: var(--md-sys-color-tertiary-container-dark);
  --color-on-tertiary-container: var(--md-sys-color-on-tertiary-container-dark);
  --color-error: var(--md-sys-color-error-dark);
  --color-on-error: var(--md-sys-color-on-error-dark);
  --color-error-container: var(--md-sys-color-error-container-dark);
  --color-on-error-container: var(--md-sys-color-on-error-container-dark);
  --color-outline: var(--md-sys-color-outline-dark);
  --color-background: var(--md-sys-color-background-dark);
  --color-on-background: var(--md-sys-color-on-background-dark);
  --color-surface: var(--md-sys-color-surface-dark);
  --color-on-surface: var(--md-sys-color-on-surface-dark);
  --color-surface-variant: var(--md-sys-color-surface-variant-dark);
  --color-on-surface-variant: var(--md-sys-color-on-surface-variant-dark);
  --color-inverse-surface: var(--md-sys-color-inverse-surface-dark);
  --color-inverse-on-surface: var(--md-sys-color-inverse-on-surface-dark);
  --color-surface-tint-at-0: var(--sys-color-surface-tint-at-0-dark);
  --color-surface-tint-at-1: var(--sys-color-surface-tint-at-1-dark);
  --color-surface-tint-at-2: var(--sys-color-surface-tint-at-2-dark);
  --color-surface-tint-at-3: var(--sys-color-surface-tint-at-3-dark);
  --color-surface-tint-at-4: var(--sys-color-surface-tint-at-4-dark);
  --color-surface-tint-at-5: var(--sys-color-surface-tint-at-5-dark);
  --color-success: var(--md-sys-color-success-dark);
  --color-on-success: var(--md-sys-color-on-success-dark);
  --color-success-container: var(--md-sys-color-success-container-dark);
  --color-on-success-container: var(--md-sys-color-on-success-container-dark);
  --color-disabled-container: var(--md-sys-color-disabled-container-dark);
  --color-disabled-content: var(--md-sys-color-disabled-content-dark);
  --color-logo: #fe8B00;
  --color-logo-opacity-50: rgba(254, 139, 0, 0.7);
  --color-logo-light: #fe8B00;
  --color-logo-inverse: #ff4500;
  --color-search-bar: #444748;
  --color-xrp: #0088a6;
  --color-usdc: #2775CA;
  --color-xlm: #b711d7;
  --color-busd: #cba00b;
  --color-reef: #CD3AAD;
  --color-shib: #eb7900;
  --color-doge: #d8aa0b;
  --color-btt: #673398;

  color-scheme: dark;
}
