.container {
  background-color: var(--color-background);
  color: var(--color-on-background);
  width: 100%;
}

@media screen and (min-width: 600px) {
  .container {
    padding-right: 2rem;
  }
}