.underlay {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-inverse-surface);
  opacity: 0.3;
  z-index: 1;
}

.overlay {
  /* important: inline-flex helps remove scrollbar when child has padding */
  display: inline-flex;
  flex-direction: column;

  max-width: calc(100% - 3.2rem);
  width: var(--max-width, fit-content);

  outline: none;
  overflow-y: auto;
  border-radius: 2.4rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
