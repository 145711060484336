@value min-laptop from "../../styles/css-values/breakpoints.module.css";

.container {
  composes: type-label-medium from global;
}

.container:global(.--sizeLarge) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  padding: 0 0rem;
  font-weight: bolder;
}

.container:global(.--sizeSmall) {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
}

.success {
  color:green;
  /* background-color: var(--md-sys-color-success-dark); */
}
.successDark {
  color:var(--md-sys-color-success-dark);
  /* background-color: var(--md-sys-color-success-dark); */
}
.fail {
  color: var(--md-ref-palette-error-50);
  /* background-color: var(--md-ref-palette-error-50); */
}
.expired {
  color: var(--md-ref-palette-error-50);
  /* background-color: var(--md-ref-palette-error-50); */
}
.pending {
  /* generate color with yellow hue for --color-pending  */
  color:var(--md-ref-palette-neutral-60);
  /* background-color: var(--md-ref-palette-neutral-60);  */
}

@media min-laptop {
  .success {
    background-color: transparent;
  }
  .fail {
    background-color: transparent;
  }
  .pending {
    background-color: transparent;
  }
  .expired {
    background-color: transparent;
  }
}