.landing-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  width: 100%;
  height: 80px;
}

.landing-header__logo {
  width: 15%;
  min-width: 150px;
}

.landing-header__logo > img {
  width: 100%;
}
