/*
  Note: you can disable pointer events on a parent and enable on a child.
*/

.pointersDisabled {
  pointer-events: none;
}

.pointersEnabled {
  pointer-events: auto;
}
