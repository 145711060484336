  .select {
    gap: 4px;
    --accent-color: #fe8B00;
  }
  
  .select .label {
    font-size: 13px;
    color: #333;
    cursor: default;
  }
  
  .select .container {
    position: relative;
  }
  
  button {
    padding: 3px 4px;
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid #bbb;
    outline: none;
    color: #444;
    min-width: 10rem;
    font-size: 14px;
    font-weight: 500;
    background: white;
    appearance: none;
    font-family: inherit;
    margin: 0;
    box-sizing: border-box;
  }
  
  button.focus-ring {
    border-color: var(--accent-color);
    box-shadow: 0 0 0 1px var(--accent-color);
  }
  
  .select button svg {
    width: 16px;
    height: 16px;
    padding: 1px;
    background: var(--accent-color);
    color: white;
    border-radius: 4px;
  }
  
  .popover {
    position: absolute;
    z-index: 10;
    top: 0;
    border: 1px solid #ccc;
    box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
    border-radius: 6px;
    background: white;
    min-width: 12rem;
  }
  
  [role="listbox"] {
    overflow: auto;
    outline: none;
    padding: 2px;
    margin: 0;
    box-sizing: border-box;
  }
  
  [role="option"] {
    margin: 2px;
    border-radius: 6px;
    padding: 4px;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-left: 0.8rem;
  }
  
  [role="option"] svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  
  [role="option"][aria-selected="true"] {
  }
  
  [role="option"]:focus {
    color: white;
    background: green;
  }
  