.segment {
  font-variant-numeric: tabular-nums;
  border-radius: 0.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.segment.placeholder {
  /* Whatever sorrounded field specifies. eg: color-on-surface-variant when surface is color-surface-variant*/
  color: inherit;
}

.segment:focus {
  color: var(--color-on-primary);
  background-color: var(--color-primary);
  outline: none;
}
