.button {
    font-size: 1.32rem;
}

.currency {
    display: flex;
    margin-left: 1.2rem;
    margin-right: 0.8rem;
    width: 3.5rem;
}

@value breakpoints: "styles/css-values/breakpoints.module.css";
@value max-large-tablet from breakpoints;
@value max-small-tablet from breakpoints;
@value max-large-phone from breakpoints;

@media max-large-phone {
    .button {
        margin-left: -1.4rem;
    }
}