.focusRing {
  border-radius: 0.8rem;
}

.focusRing:global(.--isKeyboardFocused)::before {
  width: 100%;
  height: 100%;
  transform: translate(0, 0);
  box-shadow: 0 0 0 2px var(--color-on-surface) inset;
}

.button,
.button::before,
.button::after {
  border-radius: 0.8rem;
}

.buttonContent {
  color: var(--color-on-surface);
}
