.container {
    display: 'inline-flex'; 
    max-width: fit-content;
    flex-direction: 'column'; 
}

.fields {
    display: grid;
    margin-top: -0.2rem;
    grid-auto-flow: column;
    grid-template-columns: 10fr 1fr;
}

.label {
    composes: type-label-medium from global;
    grid-area: label;
    display: inline-block;
}