.focusRing {
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.focusRing:global(.--isKeyboardFocused)::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 0.6rem);
  height: calc(100% + 0.6rem);
  transform: translate(-0.3rem, -0.3rem);
  border-radius: inherit;
  box-shadow: 0 0 0 2px var(--color-on-surface);
}

.button {
  background-color: transparent;
  height: 4rem;
  width: 4rem;
  border-radius: inherit;
  cursor: pointer;
  border-width: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.15s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* used for: hover, focus, pressed */
.button::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: var(--color-primary);
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.buttonContent {
  color: var(--color-on-surface);
  font-size: 1.8rem;
  transition: color 0.15s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* disabled state */
.button:disabled {
  background-color: transparent;
  cursor: auto;
}

.button:disabled .buttonContent {
  color: var(--color-on-surface);
  opacity: 0.38;
}

/* hovered state */

.button:not(:disabled):hover::before {
  opacity: var(--interaction-layer-hovered);
}

/* focused state */

.button:focus {
  outline: none;
}

.button:focus::before {
  opacity: var(--interaction-layer-focused);
}

/* pressed state */

.button:global(.--is-pressed)::before {
  opacity: var(--interaction-layer-pressed);
}
