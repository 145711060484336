.login-form {
  width: 350px;
  min-height: 400px;
  padding: 2rem;
  background-color: rgba(58, 70, 77, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  margin: 3rem auto;
}

.login-form__inputs {
  width: 100%;
  margin: 2rem;
}
