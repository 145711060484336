.overlay {
  /* important: inline-flex helps remove scrollbar when child has padding */
  display: inline-flex;
  flex-direction: column;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
}
