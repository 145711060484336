.elevation-0 {
  background-image: linear-gradient(rgba(0, 0, 0, 0), transparent);
}

.elevation-1 {
  background-image: linear-gradient(
    var(--color-surface-tint-at-1),
    var(--color-surface-tint-at-1)
  );
}

.elevation-2 {
  background-image: linear-gradient(
    var(--color-surface-tint-at-2),
    var(--color-surface-tint-at-2)
  );
}

.elevation-3 {
  background-image: linear-gradient(
    var(--color-surface-tint-at-3),
    var(--color-surface-tint-at-3)
  );
}

.elevation-4 {
  background-image: linear-gradient(
    var(--color-surface-tint-at-4),
    var(--color-surface-tint-at-4)
  );
}

.elevation-5 {
  background-image: linear-gradient(
    var(--color-surface-tint-at-5),
    var(--color-surface-tint-at-5)
  );
}
