.underlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.overlay {
  /* important: inline-flex helps remove scrollbar when child has padding */
  display: inline-flex;
  flex-direction: column;
  border-radius: 0.8rem;
  overflow-y: auto;
  min-width: var(--min-width);
  max-width: var(--max-width, auto);
}
