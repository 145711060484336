@property --tint-color {
  syntax: '<color>';
  inherits: false;
}

.withElevation {
  transition: --tint-color 0.15s ease-in-out;
  will-change: background-image;
}

.tint,
.both {
  background-image: linear-gradient(var(--tint-color), var(--tint-color));
}

.shadow,
.both {
  box-shadow: var(--shadow);
}
