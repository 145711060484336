.sweeper {
    display: flex;
    flex-direction: column;
}

.util {
    color: var(--txt-main);
    background-color: var(--srf-main);
    margin-bottom: 1rem;
}

.util > .util__main-address,
.util > .util__latest-block {
    margin: 5px 0 0 20px;
    font-size: 1.8rem;
    font-weight: 400;
    /* display: inline-block; */
}

.util__main-address > span {
    cursor: pointer;
}

.util__main-address > span:hover {
    color: var(--primary);
}
