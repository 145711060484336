.sidenav-wrapper {
  /* position: absolute; */
  /* left: 1rem; */
  /* top: 50; */
  position: relative;
  height: 100vh;
  width: 200px;
  height: calc(100vh - 50px);
  background-color: var(--bg-header);
  transition: all 0.4s ease;
}

.sidenav-wrapper-minimized {
  /* position: absolute; */
  /* left: 1rem; */
  /* top: 50; */
  position: relative;
  height: 100vh;
  width: 0;
  height: calc(100vh - 50px);
  background-color: transparent;
  transition: all 0.4s ease;
}

.sidenav {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  white-space: nowrap;
  margin-top: 2rem;
  /* padding-left: 2rem; */
}

.sidenav-minimized {
  display: none;
}

.sidenav-toggle {
  position: absolute;
  color: var(--txt-main);
  &.show{
    top: 0;
    right: 0;
    height: 33px;
  }
  &.hide{

  }
}

.nav-link,
.sidenav-dropdown__label {
  padding-left: 2rem;
  margin: 10px 0;
  text-decoration: none;
  color: var(--txt-main);
  cursor: pointer;
}

.nav-link:hover,
.sidenav-dropdown__label:hover {
  color: var(--txt-dark);
}

.selected {
  color: var(--primary) !important;
}

.sidenav-dropdown__label {
  display: flex;
  align-items: center;
}

.sidenav-link-icon {
  margin-left: 5px;
}

.sidenav-dropdown-hidden {
  display: none;

  transition: all 0.3s ease;
}
.sidenav-dropdown {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  background-color: var(--srf-main);
}

.sidenav-dropdown > .nav-link {
  font-size: 1.4rem;
}
