.calendar {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
.header {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 0 8px 12px 8px;
}

.header .dropdowns {
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
}

.select {
    user-select: none;
    color: var(--color-on-surface);
    border: none;
    border-radius: 0rem;
    font-size: 1.5rem;
    height: 3rem;
    width: 8rem;
    background-color: transparent;
    cursor: pointer;
}

.select > * {
    cursor: pointer;
    outline: none;
    height: auto;
}

option {
    border: none;
    background-color: var(--color-surface);
}
.calendar {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
.header {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 0 8px 12px 8px;
}

.header .dropdowns {
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
}

.select {
    user-select: none;
    color: var(--color-on-surface);
    border: none;
    border-radius: 0.8rem;
    font-size: 1.5rem;
    height: 3rem;
    width: 8rem;
    background-color: transparent;
    cursor: pointer;
}

.select > * {
    color: var(--color-surface);
    outline: none;
    height: auto;
}

option {
    height: 12rem;
}