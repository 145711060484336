.label {
  composes: type-title-medium from global;
  text-align: center;
  margin-bottom: 0.8rem;
  color: var(--color-on-surface);
}

.label:global(.--isVariant) {
  color: var(--color-on-surface-variant);
}

.listbox {
  outline: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: var(--color-on-surface);
  --hover-background: var(--color-surface-tint-at-2);
  --focus-background: var(--color-surface-tint-at-2);
  --selected-indicator-color: var(--color-secondary);
}

.listbox:global(.--isVariant) {
  color: var(--color-on-surface-variant);
}

.section:not(:last-child) {
  border-bottom: 1px solid var(--color-outline);
  margin-bottom: 0.8rem;
  padding-bottom: 0.4rem;
  width: 100%;
  cursor: default;
}

.sectionHeading {
  composes: type-title-small from global;
  text-transform: uppercase;
  margin-left: 1.6rem;
}

.sectionGroup {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.option {
  cursor: default;
  display: flex;
  width: 100%;
  position: relative;
  padding-right: 4rem;
  color: var(--md-sys-color-inverse-surface-dark);
}

.option:hover {
  background-color: var(--hover-background);
}

.option:focus {
  outline: none;
}
.option:global(.--isFocused) {
  background-color: var(--focus-background);
}

.option:global(.--focusVisible)::before {
  content: '';
  display: block;
  width: 0.3rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-on-surface);
  border-radius: 0 50% 50% 0;
}

.optionIcon {
  width: 2.4rem;
  height: 4rem;
  margin-left: 1.2rem;
  display: flex;
  align-items: center;
  font-size: 100px;
}

.optionIcon > img {
  width: 100%;
  aspect-ratio: 1 / 1;
}

.optionContent {
  flex-grow: 1;
  min-height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.4rem 1.6rem;
}

/* if the optionContent is placed next to an icon, the padding should be smaller*/
.optionIcon ~ .optionContent {
  padding-left: 0.8rem;
}

.optionLabel {
  composes: type-label-large from global;
}

.optionDescription {
  composes: type-label-medium from global;
}

.optionSelectedIndicator {
  position: absolute;
  right: 1.2rem;
  height: 4rem;
  display: flex;
  align-items: center;
  color: var(--selected-indicator-color);
}
