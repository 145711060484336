*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  font-family: 'inter', sans-serif;
}

body {
  /* font-family: 'Roboyo', sans-serif; */
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.7;
  box-sizing: border-box;
  overflow: hidden;
}

@media screen and (max-width: 500px) {
  html {
    font-size: 55%;
  }
}
