.users {
  width: 100%;
  /* height: 100%; */
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  border: 1px solid var(--border);
  color: var(--txt-main);
}

thead {
  background-color: var(--form-bg);
  font-size: 1.6rem;
  /* border: 1 */
  /* border-radius: 50px; */
}

th {
  border: 1px solid var(--border);
}

tbody {
  background-color: var(--srf-main-light);
  min-height: 400px;
  font-size: 1.4rem;
  /* border: 2px solid var(--border); */
}

td {
  padding: 1rem;
  
}

tbody > tr:hover {
  background-color: var(--form-bg);
  cursor: pointer;
}