.container {
  padding: 2.4rem;
  padding-bottom: 1.6rem;
  border-radius: inherit;
}

.container:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--color-outline) inset;
}

.title {
  composes: type-title-large from global;
  max-width: 60ch;
  margin-bottom: 2.4rem;
  text-align: center;
}
