.padding {
  padding: 0.8rem 0;
}

.values {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.values > img {
  width: 2rem;
  height: 2rem;
}